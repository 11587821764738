<script lang="ts">
	import { createEventDispatcher, onMount } from 'svelte';
	import ImageFallback from './ImageFallback.svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { browser } from '$app/environment';

	type LoaderArgs = { quality?: number; src: string; width: number };
	const cloudflaredLoader = ({ src, width }: LoaderArgs) =>
		`${src.replace(
			'https://cdn.thearc.dev',
			'https://rust-cdn-fs5s54ukva-ey.a.run.app'
		)}?width=${Math.min(width, 2048)}&no_upscale=True`;
	const storyblokLoader = ({ src, width, quality = 75 }: LoaderArgs) =>
		`${src}/m/${Math.min(width, 2048)}x0/filters:quality(${quality})`;

	export let width: number | undefined = undefined;
	export let height: number | undefined = undefined;
	export let src: string | null | undefined;
	export let shouldShowLoad: boolean = false;
	export let ref: HTMLImageElement | null = null;
	export let alt: string | undefined = undefined;
	export let sizes = '100vw';
	export let className = '';
	export let style = '';
	export let title: string | undefined | null = undefined;
	let loaded = false;
	let errored = false;
	export { className as class };

	let scrollTop = 0;
	let scrollY = 0;

	const dispatch = createEventDispatcher();
	$: scrollProgress = (() => {
		if (!ref) {
			return;
		}
		const { top, height } = ref.getBoundingClientRect();
		return Math.max(0, Math.min(1, (top + height) / window.innerHeight));
	})();

	$: loader = (() => {
		if (!src) return () => ``;

		const parsed = new URL(src);
		switch (parsed.host) {
			case 'cdn.thearc.dev':
				return cloudflaredLoader;
			case 'a.storyblok.com':
				return storyblokLoader;
			default:
				return () => ``;
		}
	})();

	$: srcset = (() => {
		if (!src || !loader) return undefined;
		const srcType = typeof src;
		if (srcType !== 'string') {
			throw new Error(`Invalid type passed to Image component! Expected string, got ${srcType}`);
		}
		if (src?.endsWith('.svg')) return undefined;

		const widths = [48, 96, 320, 640, 960, 1280, 1600, 1920, 2048];

		return widths.map((w) => `${loader({ src, width: w })} ${w}w`).join(', ');
	})();
</script>

<svelte:window bind:scrollY />

{#if src && !errored}
	<img
		src={src ? (src.endsWith('.svg') ? src : loader({ src, width: width ?? 768 })) : ''}
		{srcset}
		{sizes}
		bind:this={ref}
		on:load={() => {
			loaded = true;
			dispatch('load');
		}}
		on:error={() => (errored = true)}
		class={classes(className, loaded || !shouldShowLoad ? '' : 'bg-gray-200 animate-pulse')}
		{alt}
		{width}
		{height}
		{style}
		{title}
		{...$$restProps}
	/>
{:else}
	<ImageFallback class={className} {width} {height} {...$$restProps} />
{/if}
